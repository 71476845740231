import React from 'react';
import Layout from '../../../components/Layout';
import Section from '../../../components/Section';
import { Container, Row, Column } from '../../../components/Grid';
import { Link } from 'gatsby';
import Image from '../../../components/Image';
import Icon from '../../../components/Icon';
import Action from '../../../components/Resource/Action';
import Video from '../../../components/Video';
import '../../../styles/pages/_classroom-instruction.scss';

const ProfileHenry = () => {
  return (
    <Layout title="Career Profiles | Katie Creme Henry">
      <Section className="educators-action__video-hero pt-2 pb-0">
        <Container fullWidth className="educators-action__video-hero-container">
          {/* Breadcrumbs */}
          <Row className="educators-action__video-hero-intro">
            <Column>
              <h4>
                <Link to="/college-career-readiness/career-profiles/">
                  College and Career Readiness |
                </Link>
                <span> Career Profiles</span>
              </h4>
            </Column>
          </Row>
          {/* Name and Title */}
          <Row className="educators-action__video-hero-intro">
            <Column>
              <h1>
                Katie Creme Henry
                <span className="career-profiles__title pl-1">
                  Senior Manager of Patient Advocacy Relations
                </span>
              </h1>
            </Column>
          </Row>
        </Container>
      </Section>

      <Section className="pt-2">
        <Container>
          {/* Profile Video */}
          <Row className="educators-action__video-hero-videos">
            <Column size={8} className="pr-3">
              <Video
                className="hero-img-video__video"
                guidSrc="cd2ed766-3841-433a-82d3-962ceaba4990"
                poster="img-Career-Profiles-K-Henry@2x.jpg"
                autoPlay={false}
              />

              <Section className="pr-3">
                <h3 className="career-profiles__at-a-glance-header">
                  Advocating for Patient Needs
                </h3>
                <div className="educators-action__theme-content-body">
                  <h3>Overview</h3>
                  <p>
                    Senior Managers of Patient Advocacy Relations work with
                    patient advocacy organizations to understand the patient
                    experience and use data to inform better medical products
                    and services. While not initially interested in STEM as a
                    youth, Katie found a passion for patient advocacy and ended
                    up finding a role where she has the chance to positively
                    impact thousands of lives through her work.
                  </p>
                  <h3>Reflect</h3>
                  <p>
                    Find out how well this career lines up with your students’
                    interests and strengths:
                  </p>
                  <ul>
                    <li>
                      Strong communication and interpersonal skills enable a
                      good understanding of patient needs
                    </li>
                    <li>
                      Synthesizing insights is important when interpreting data.
                    </li>
                    <li>
                      Passion for problem-solving is essential to overcoming
                      challenges in the medical system.
                    </li>
                    <li>
                      Excellent communication skills are used to understand and
                      convey patient feedback.
                    </li>
                  </ul>
                  <h3>Activate</h3>
                  <p>
                    Students can investigate what it takes to become a Senior
                    Manager of Patient Advocacy Relations by exploring the
                    companion Career Profile, featuring background information
                    about this career, job outlook, and more.
                  </p>
                </div>
              </Section>
            </Column>

            {/* RIGHT COLUMN */}
            <Column size={4}>
              <div className="educators-action__video-hero-thumbs">
                <div className="educators-action__video-hero-thumbs-item">
                  <span className="educators-action__video-hero-thumb">
                    <Image filename="thmb-O-Abimbola@2x.jpg" />
                  </span>
                  <span className="educators-action__video-hero-text">
                    <Link to="/college-career-readiness/career-profiles/profile-quality-assurance">
                      <h3>Senior Supervisor of Quality Assurance</h3>
                      <span>4:42min</span>
                    </Link>
                  </span>
                </div>
                {/* Video 2 */}
                <div className="educators-action__video-hero-thumbs-item">
                  <span className="educators-action__video-hero-thumb">
                    <Link to="/college-career-readiness/career-profiles/profile-developmental-science">
                      <Image filename="thmb-D-Mandikian@2x.jpg" />
                    </Link>
                  </span>
                  <span className="educators-action__video-hero-text">
                    <Link to="/college-career-readiness/career-profiles/profile-developmental-science">
                      <h3>Senior Scientist in Development Sciences</h3>
                      <span>4:41min</span>
                    </Link>
                  </span>
                </div>
                {/* Video 3 */}
                <div className="educators-action__video-hero-thumbs-item">
                  <span className="educators-action__video-hero-thumb">
                    <Link to="/college-career-readiness/career-profiles/profile-data-science">
                      <Image filename="thmb-P-Dhillon@2x.jpg" />
                    </Link>
                  </span>
                  <span className="educators-action__video-hero-text">
                    <Link to="/college-career-readiness/career-profiles/profile-data-science">
                      <h3>Principal Scientist in Development Sciences</h3>
                      <span>4:53min</span>
                    </Link>
                  </span>
                </div>
                {/* Video 4 */}
                <div className="educators-action__video-hero-thumbs-item">
                  <span className="educators-action__video-hero-thumb">
                    <Link to="/college-career-readiness/career-profiles/profile-companion-diagnostic-care">
                      <Image filename="thmb-D-Kim@2x.jpg" />
                    </Link>
                  </span>
                  <span className="educators-action__video-hero-text">
                    <Link to="/college-career-readiness/career-profiles/profile-companion-diagnostic-care">
                      <h3>
                        Senior Project Leader of Companion & Clinical
                        Diagnostics Development
                      </h3>
                      <span>4:37min</span>
                    </Link>
                  </span>
                </div>
                {/* Video 5 */}
                <div className="educators-action__video-hero-thumbs-item">
                  <span className="educators-action__video-hero-thumb">
                    <Link to="/college-career-readiness/career-profiles/profile-diversity-and-inclusion">
                      <Image filename="thmb-D-Navarro@2x.jpg" />
                    </Link>
                  </span>
                  <span className="educators-action__video-hero-text">
                    <Link to="/college-career-readiness/career-profiles/profile-diversity-and-inclusion">
                      <h3>Diversity & Inclusion Lead</h3>
                      <span>4:23min</span>
                    </Link>
                  </span>
                </div>
              </div>
              {/* Video 6 */}
              <div className="educators-action__video-hero-thumbs-item">
                <span className="educators-action__video-hero-thumb">
                  <Link to="/college-career-readiness/career-profiles/profile-patient-advocacy">
                    <Image filename="thmb-K-Henry@2x.jpg" />
                  </Link>
                </span>
                <span className="educators-action__video-hero-text">
                  <Link to="/college-career-readiness/career-profiles/profile-patient-advocacy">
                    <h3>Senior Manager of Patient Advocacy Relations</h3>
                    <span>4:25min</span>
                  </Link>
                </span>
              </div>

              {/* Quote */}
              <div className="educators-action__educator-quote">
                <h3>
                  "Connect to organizations in your community who do things
                  you're interested in."
                </h3>
                <h4>Katie Creme Henry</h4>
              </div>

              {/* Career Profile Card */}
              <div className="educators-action__educator-guide">
                <div className="educators-action__educator-guide-heading">
                  <h4>Career Profile</h4>
                </div>
                <div className="educators-action__educator-guide-body">
                  <h3 className="educators-action__educator-guide-content-heading">
                    Senior Manager of Patient Advocacy Relations
                  </h3>
                  <span className="educators-action__educator-guide-duration">
                    <Icon name="clock" marginRight />
                    10–15 minutes
                  </span>
                  <p>
                    The Senior Manager of Patient Advocacy Relations is a
                    company’s primary link to the patients they serve, working
                    directly with individuals and groups to gain insight into
                    patient needs. They also serve as liaison between health
                    professionals, administrators, and insurance companies.
                  </p>
                  <Action
                    type="download"
                    label="Career Profile"
                    path="/pdfs/career-readiness/Gen-CR-Patient-Advocacy.pdf"
                    format=".PDF,"
                    size="145 KB"
                  />
                </div>
              </div>
            </Column>
          </Row>
        </Container>
      </Section>
    </Layout>
  );
};

export default ProfileHenry;
